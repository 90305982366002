document.getElementById('moneyTransferForm').addEventListener('submit', (event) => {
  event.preventDefault(); // Prevent form submission

  // Retrieve input values
  const amount = parseInt(document.getElementById('amount').value);
  const transaction_limit_amount = parseInt(document.getElementById('trnxn-limit-amount').value);
  const no_of_transactions = parseInt(document.getElementById('no-of-trnxn').value);
  const month = parseInt(document.getElementById('month').value);

  // Clear existing schedule table
  const scheduleTableBody = document.querySelector('#scheduleTable tbody');
  scheduleTableBody.innerHTML = '';

  // Generate random numbers
  let sum = 0;
  let randomNumbers = [];
  while (sum !== amount) {
    randomNumbers = [];
    sum = 0;

    for (let i = 0; i < no_of_transactions; i++) {
      const randomNumber = Math.floor(Math.random() * transaction_limit_amount) + 1;
      randomNumbers.push(randomNumber);
      sum += randomNumber;
    }

    if (sum > amount) {
      const diff = sum - amount;
      let randomIndex = Math.floor(Math.random() * randomNumbers.length);

      while (randomNumbers[randomIndex] <= diff) {
        randomIndex = Math.floor(Math.random() * randomNumbers.length);
      }

      randomNumbers[randomIndex] -= diff;
      sum = amount;
    }
  }

  // Generate schedule dates
  const startDate = new Date(new Date().getFullYear(), month - 1, 2);
  const scheduleDates = [];
  scheduleDates.push(startDate);

  while (scheduleDates.length < randomNumbers.length) {
    const currentDate = scheduleDates[scheduleDates.length - 1];
    const randomDays = Math.floor(Math.random() * 2) + 1;
    const nextDate = new Date(currentDate.getTime() + randomDays * 24 * 60 * 60 * 1000);
    if (nextDate.getMonth() === month - 1) {
      scheduleDates.push(nextDate);
    }
  }

  // Display schedule in table
  let totalAmount = 0; // Variable to hold the sum of amounts

  for (let i = 0; i < randomNumbers.length; i++) {
    const date = scheduleDates[i].toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });

    const row = document.createElement('tr');
    row.classList.add('not-selected'); // Add the "not-selected" class by default
    const dateCell = document.createElement('td');
    dateCell.classList.add('trnxn-date');
    dateCell.textContent = formatDateString(date); // Format date string
    const amountCell = document.createElement('td');
    amountCell.classList.add('trnxn-amount');
    amountCell.textContent = formatAmount(randomNumbers[i]); // Format amount

    const checkmark = createCheckmarkSVG(); // Create checkmark SVG element
    checkmark.classList.add('checkmark');
    checkmark.classList.add('hidden');
    amountCell.appendChild(checkmark);
    row.appendChild(dateCell);
    row.appendChild(amountCell);
    scheduleTableBody.appendChild(row);

    // Add click event listener to toggle row selection and checkmark
    row.addEventListener('click', () => {
      row.classList.toggle('selected');
      row.classList.toggle('not-selected');
      const checkmark = row.querySelector('.checkmark');
      checkmark.classList.toggle('hidden');
    });

    totalAmount += randomNumbers[i]; // Add amount to the total
  }

  // Update total amount cell
  const totalAmountCell = document.getElementById('totalAmountCell');
  totalAmountCell.textContent = formatAmount(totalAmount); // Format total amount

  // Function to format amount in Indian Rupees
  function formatAmount(amount) {
    const formatter = new Intl.NumberFormat('en-IN');
    return formatter.format(amount);
  }

  // Function to format the date string as DD/MM/YYYY
  function formatDateString(dateString) {
    const parts = dateString.split('/');
    return `${parts[1]}/${parts[0]}/${parts[2]}`;
  }

  // Function to create checkmark SVG element
  function createCheckmarkSVG() {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svg.setAttribute('width', '16');
    svg.setAttribute('height', '16');
    svg.setAttribute('fill', 'currentColor');
    svg.setAttribute('class', 'bi bi-check-circle-fill');
    svg.setAttribute('viewBox', '0 0 16 16');

    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute('d', 'M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z');

    svg.appendChild(path);
    return svg;
  }
});
